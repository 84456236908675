@import "../styles/variable.module.scss";
@import "~bootstrap/scss/bootstrap";
// @import '~swiper/swiper.min.css';
// @import '~swiper/modules/navigation/navigation.min.css';
// [dir="ltr"] {
//   @import "~bootstrap/scss/bootstrap";
// }
[dir="rtl"] {
  * {
    direction: rtl;
  }
  @import "~bootstrap/dist/css/bootstrap.rtl.min";
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../public/fonts/DM_Sans/DMSans-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../public/fonts/Poppins/Poppins-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../public/fonts/Poppins/Poppins-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../public/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../public/fonts/Poppins/Poppins-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../public/fonts/Almarai/Almarai-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../public/fonts/Almarai/Almarai-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../public/fonts/Almarai/Almarai-ExtraBold.woff2") format("woff2");
}

:root {
  --background-color: #fcfcfd;
  --box-color: #fcfcfd;
  --desc-font: #141416;
  --border-color: #e6e8ec;
  --light-bg: #f4f5f6;
  --smallFont: #353945;
  --variantColor: #141416;
  --light-font: #353945;
  --block-color: #fcfcfd;
  --borderSec-color: #e6e8ec;
  --hover-border-card: #c3c3c3;
  --p-color: #353945;
}

[data-theme="dark"] {
  --background-color: #141416;
  --box-color: #353945;
  --desc-font: #fcfcfd;
  --border-color: #353945;
  --light-bg: #23262f;
  --smallFont: #e6e8ec;
  --variantColor: #fcfcfd;
  --light-font: #777e90;
  --block-color: #23262f;
  --borderSec-color: #23262f;
  --hover-border-card: #777e90;
  --p-color: #777e90;
}
[dir="rtl"] {
  body {
    font-family: $fontFamilyrtl;
  }
}
[dir="ltr"] {
  body {
    font-family: $fontFamilyltr;
  }
}
[dir="rtl"] * {
  font-family: $fontFamilyrtl !important;
}
.disableDiv {
  pointer-events: none;
  opacity: 0.6;
}

.BodyFont{
  font-size: 16px;
  line-height: 24px;
  color: var(--light-font);
  @include medium_device{
    font-size: 14px;
    line-height: 22px;
  }
}

.hero,
.h1,
.h2,
.h3,
.h4,
[class^="btn"] {
  @include headingFont(font-family, "DM Sans", "Almarai");
}
[dir="rtl"],
[dir="ltr"] {
  [class$="grid-column-4"] {
    margin-top: 30px;
    @include extra_large {
      grid-template-columns: auto auto auto;
    }
    @include medium_device {
      grid-template-columns: auto auto;
    }
    @include mobile_device {
      grid-template-columns: auto;
    }
  }
  .quill {
    .ql-tooltip {
      display: none;
    }
    .ql-container {
      font-family: $fontFamilyltr;
      .ql-editor {
        border: 2px solid var(--border-color);
        border-radius: 12px;
        height: 120px;
        &.ql-blank {
          &:before {
            font-style: normal;
            color: $customFont;
            font-weight: 400;
          }
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: var(--variantColor);
        }
      }
    }
  }
  // pagination
  .pagination {
    margin: 0 -10px -10px;
    @include medium_device {
      margin: 0 -5px -10px;
    }
    .page-item {
      .page-link {
        padding: 0 16px;
        width: auto;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin:0 10px 10px;
        border-radius: 4px;
        background: var(--background-color);
        border: 1px solid var(--border-color);
        color: var(--variantColor);
        font-weight: 500;
        font-size: 12px;
        @include medium_device {
          margin: 0 5px 10px;
        }
      }
      &.active {
        .page-link {
          color: $lightColor;
          background-color: $themeColor;
        }
      }
    }
  }
  .walletconnect-modal__base {
    background-color: var(--background-color);
    box-shadow: none;
    .walletconnect-modal__mobile__toggle {
      background-color: var(--light-bg);
      cursor: pointer;
      .walletconnect-modal__mobile__toggle_selector {
        background-color: var(--background-color);
        color: var(--variantColor);
      }
      a {
        font-size: 14px;
      }
    }
    .walletconnect-search__input {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      background: none;
      border: 2px solid var(--border-color);
      color: var(--variantColor);
      border-radius: 12px;
      @include fontWeight(font-weight, 500, 700);
      font-size: 14px;
      line-height: 1.66667;
      transition: border-color 0.2s;
      &::placeholder {
        color: $customFont;
        font-weight: 400;
      }
      &:focus {
        border-color: $customFont;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
      }
    }

    .walletconnect-connect__button__text {
      font-size: 14px !important;
      line-height: normal;
      color: $customFont;
    }
    .walletconnect-qrcode__text {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
  .walletconnect-modal__footer {
    & > a {
      line-height: 20px;
    }
  }
  .tabSwiper {
    .swiper-slide {
      width: auto !important;
      padding: 2px 16px;
      border-radius: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &.active {
        background-color: $secondaryBg;
        color: $lightColor;
      }
    }
  }
  .tabSlider {
    .swiper-button-next,
    .swiper-button-prev {
      width: 28px;
      height: 28px;
      -webkit-transform: translateY(30%);
      transform: translateY(30%);
      border-color: var(--border-color);
      &::after {
        width: 24px;
        height: 24px;
        background-image: url("../public/img/leftArrow.svg");
      }
      @include extra_large {
        top: 50%;
      }
    }
    .swiper-button-prev {
      left: auto;
      right: -40px;
      -webkit-transform: rotate(180deg) translateY(-30%);
      transform: rotate(180deg) translateY(-30%);
    }
    .swiper-button-next {
      right: -74px;
      @include extra_large {
        left: auto;
      }
    }
    .swiper-slide {
      & > span {
        margin-right: 8px !important;
      }
    }
  }
  .swiper-rtl {
    [class*="button-next"],
    [class*="button-prev"] {
      &::after {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        background-position: center;
      }
    }
  }
  [class*="button-next"],
  [class*="button-prev"] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    width: 40px;
    height: 40px;
    border: 2px solid transparent;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    line-height: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      width: 31px;
      height: 30px;
      background-position: center;
    }
    &:hover {
      border-color: var(--border-color);
    }
    @include extra_large {
      top: auto;
      bottom: -30px;
    }
  }
  [class*="button-next"] {
    &::after {
      background-image: url("../public/nextArrow.svg");
    }
  }
  [class*="button-prev"] {
    &::after {
      background-image: url("../public/prevArrow.svg");
    }
  }
  [class*="swiper-initialized"] ~ {
  [class*="button-next"],
  [class*="button-prev"] {
      display: flex !important;
    }
  }
  .swiper {
    padding: 30px 0 0;
  }
  .infinite-scroll-component {
    padding-top: 10px;
    [class*="row"] {
      margin: 0 -10px;
      // @media only screen and (min-width: 1200px){
      //   margin: 0 -10px;
      // }
    }
  }
  .intercom-lightweight-app,
  .intercom-launcher-frame {
    z-index: 99 !important;
  }
  .row {
    & > * {
      padding-right: 15px;
      padding-left: 15px;
      @include extra_large {
        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }
  .Toastify {
    .Toastify__toast-container {
      z-index: 9999999;
    }
  }
  .alert {
    margin-bottom: 0;
    border-radius: 50px;
  }
  .react-datepicker-popper {
    .react-datepicker {
      box-shadow: 0px 64px 64px rgb(31 47 70 / 12%);
      border: 1px solid var(--border-color);
      background-color: var(--background-color);
      color: var(--variantColor);
      .react-datepicker__header {
        border-bottom: 1px solid var(--border-color);
        background: var(--light-bg);
      }
      .react-datepicker__time-container {
        border-left-color: var(--border-color);
        width: 72px;
      }
      .react-datepicker__navigation-icon {
        &::before {
          border-width: 2px 2px 0 0;
        }
      }
      .react-datepicker__day,
      .react-datepicker__current-month,
      .react-datepicker__day-name,
      .react-datepicker-time__header {
        color: var(--variantColor);
      }
      .react-datepicker__day {
        border-radius: 50px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: var(--light-bg);
          color: var(--variantColor);
        }
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--today {
        background-color: var(--variantColor);
        color: var(--background-color);
      }
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__time {
        color: var(--variantColor);
        background-color: var(--background-color);
      }
      .react-datepicker__time-container {
        .react-datepicker__time {
          .react-datepicker__time-box {
            width: 72px;
            ul {
              &.react-datepicker__time-list {
                li {
                  -webkit-transition: all 0.3s ease-in-out;
                  transition: all 0.3s ease-in-out;
                  &.react-datepicker__time-list-item--selected {
                    background-color: var(--variantColor);
                    color: var(--background-color);
                    -webkit-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                      background-color: var(--light-bg);
                      color: var(--variantColor);
                    }
                  }
                  &.react-datepicker__time-list-item {
                    &:hover {
                      background-color: var(--light-bg);
                      color: var(--variantColor);
                    }
                  }
                }
              }
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }
    }
    &[data-placement^="top"] {
      .react-datepicker__triangle {
        &::before {
          border-top-color: var(--border-color);
        }
        &::after {
          border-top-color: var(--background-color);
        }
      }
    }
    &[data-placement^="bottom"] {
      .react-datepicker__triangle {
        &::before {
          border-bottom-color: var(--border-color);
        }
        &::after {
          border-bottom-color: var(--light-bg);
        }
      }
    }
    .react-datepicker__navigation-icon {
      &::before {
        top: 14px;
      }
    }
  }
  ul {
    padding: 0;
  }
  .dark {
    display: none !important;
  }
  .loadmoreBtn {
    @include mobile_device {
      width: 100%;
    }
  }
  .pageLoader {
    min-height: calc(100vh - 250px);
    display: flex;
    align-items: center;
    justify-content: center;
    & > [class^="btn"] {
      &[class*="buttonSmall"] {
        box-shadow: none;
        font-size: 0;
        &:hover {
          background-color: transparent;
          box-shadow: none;
        }
        & > div {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
  }
  .container {
    @media only screen and (min-width: 1921px){
      max-width: 1841px;
    }
    @media only screen and (min-width: 2400px){
      max-width: 2270px;
    }
    @include large_device {
      max-width: 100%;
    }
  }
  .customBTab {
    &.alignRight {
      ul {
        justify-content: flex-end;
      }
    }
    &.lightTab > {
      ul {
        .nav-link {
          @include fontWeight(font-weight, 500, 700);
          line-height: 24px;
          padding: 10px 16px;
          border-radius: 28px;
          &.active {
            background: var(--light-bg);
            color: var(--desc-font);
          }
        }
      }
    }
    .nav-tabs {
      border: none;
      display: flex;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: hidden;
      margin: 0;
      min-height: 44px;
      max-height: 44px;
      &::-webkit-scrollbar {
        height: 5px;
        visibility: hidden;
        transition: visibility 5s;
        cursor: all-scroll;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--border-color);
        border-radius: 8px;
      }
      &:hover {
        overflow-x: auto;
      }
      li {
        display: inline-block;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
        @include mobile_device {
          margin-right: 8px;
        }
      }
    }
    .nav-link {
      border: none;
      color: $customFont !important;
      flex-shrink: 0;
      padding: 10px 16px;
      border-radius: 100px;
      background: none;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      transition: all 0.2s;
      font-family: inherit;
      &.active {
        color: var( --variantColor) !important;
        background-color: var(--light-bg);
      }
      &:hover{
        color: var( --variantColor) !important;
      }
      @include extra_large {
        margin-right: 0;
      }
    }
  }
  body {
    background-color: var(--background-color);
    margin: 0;
    font-size: 14px;
    line-height: 1.71429;
    color: var(--desc-font);
    .hero,
    .h1,
    .h2,
    .h3,
    .h4 {
      font-weight: 700;
    }
    a {
      text-decoration: none;
      color: var(--variantColor);
      &:hover {
        color: $themeColor;
      }
      &[class^="sc-"] {
        color: $themeColor;
      }
    }
    .h1 {
      font-size: 64px;
      line-height: 1;
      letter-spacing: -0.02em;
    }
    .h2 {
      font-size: 48px;
      line-height: 1.16667;
      letter-spacing: -0.02em;
      @include mobile_device {
        font-size: 24px;
      }
      &.mainTitle {
        margin-bottom: 24px;
        @include mobile_device {
          font-size: 32px;
        }
        svg {
          margin-right: 15px;
        }
        span {
          color: $customFont;
          margin-right: 10px;
        }
      }
    }
    .h3 {
      font-size: 40px;
      line-height: 1.2;
      letter-spacing: -0.01em;
      @include medium_device {
        font-size: 32px;
      }
    }
    .h4 {
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: -0.01em;
    }
    [class^="btn"] {
      height: 48px;
      padding: 0 24px;
      @include headingFont(font-family, "DM Sans", "Almarai");
      color: $lightColor;
      background: $themeColor;
      font-size: 16px;
      font-weight: 600;
      border-radius: $borderRadius;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: none;
      &:active:focus {
        box-shadow: none;
      }
      &.p-end,
      &.start {
        svg {
          position: absolute;
        }
      }
      &.done {
        background: var(--border-color);
        border-color: var(--border-color);
        &:hover {
          background: var(--border-color);
          border-color: var(--border-color);
        }
      }
      &:hover,
      &:focus {
        background: $themeHoverColor !important;
        color: $lightColor !important;
        svg * {
          fill: $lightColor;
        }
      }
      &.buttonStroke { 
        background: none;
        -webkit-box-shadow: 0 0 0 2px $customFont inset !important;
        box-shadow: 0 0 0 2px $customFont inset !important;
        color: var(--desc-font);
        border: none;
        &:hover,
        &:focus {
          background: $themeColor;
          -webkit-box-shadow: 0 0 0 2px $themeHoverColor inset !important;
          box-shadow: 0 0 0 2px $themeHoverColor inset !important;
          color: $lightColor;
        }
        &.defaultBtn {
          &:hover {
            cursor: default;
            background-color: transparent;
            color: inherit;
            -webkit-box-shadow: 0 0 0 2px var(--border-color) inset !important;
            box-shadow: 0 0 0 2px var(--border-color) inset !important;
            svg * {
              fill: var(--variantColor);
            }
          }
        }
      }
      &.buttonSmall {
        height: 40px;
        border-radius: 20px;
        padding: 0 16px;
        font-size: 14px;
      }
      .btn-primary {
        &:disabled {
          background-color: $themeColor;
          border-color: $themeColor;
        }
      }
      &.chksvg {
        background: rgba(55, 114, 255, 0.1);
        box-shadow: none !important;
        color: var(--variantColor);
        span {
          margin: 0 12px 0 0;
        }
      }
    }
    [class^="btn"]:disabled {
      color: $lightColor;
      background: $themeColor;
    }
  }
  .card {
    border: none;
    background: transparent;
  }
  .nav-link {
    color: var(--desc-font);
    transition: color .2s ease 0s;
    position: relative;
    font-size: 14px;
    &:hover {
      color: $themeColor;
    }
  }
  ul,
  ol {
    list-style-type: none;
    margin-bottom: 0;
  }
  .form-control {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    background: none;
    border: 2px solid var(--border-color);
    color: var(--variantColor);
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.66667;
    text-transform: inherit;
    transition: border-color 0.2s;
    &::placeholder {
      color: $customFont;
      font-weight: 400;
    }
    &:focus {
      border-color: $customFont;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;
      color: var(--variantColor);
    }
    &:disabled {
      background-color: var(--border-color);
    }
  }
  html {
    box-sizing: border-box;
  }
  .gx-4 {
    --bs-gutter-x: 30px;
  }
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  img,
  fieldset,
  a img {
    border: none;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    -webkit-appearance: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="submit"],
  button {
    cursor: pointer;
  }
  input[type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  button {
    background: none;
  }
  textarea {
    overflow: auto;
    &.form-control {
      height: auto;
      padding: 12px 16px;
      resize: none;
    }
  }
  input,
  button {
    margin: 0;
    padding: 0;
    border: 0;
  }
  div,
  input,
  textarea,
  select,
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  .btn,
  .dropdown-toggle {
    &:focus {
      outline: none;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }
  // check
  .form-check {
    display: flex;
    input {
      position: absolute;
      top: 0;
      opacity: 0;
      width: 24px;
      height: 24px;
      z-index: 3;
    }
    .form-check-input {
      margin: 0;
      cursor: pointer;
      width: 24px;
      height: 24px;
      cursor: pointer;
      &:checked {
        & + label {
          &:before {
            background: $themeColor;
            border-color: $themeColor;
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
    .form-check-label {
      line-height: 1.71429;
      @include fontWeight(font-weight, 500, 700);
      color: var(--desc-font);
      position: relative;
      &:before,
      &::after {
        content: "";
        position: absolute;
      }
      &::before {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 2px solid var(--border-color);
        transition: all 0.2s;
      }
      &:after {
        top: 50%;
        width: 14px;
        height: 10px;
        opacity: 0;
        transition: opacity 0.2s;
        background-image: url("../public/check.svg");
      }
    }
  }
  .form-switch {
    .form-check-input {
      width: 48px;
      height: 24px;
      background-color: var(--border-color);
      border: none;
      background-image: url("../public/switch.svg");
      &:checked {
        background-color: $themeColor;
        background-image: url("../public/switch-w.svg");
        background-size: 18px;
        background-position: 95% 50%;
      }
      &:focus {
        border-color: $themeColor;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: $customFont;
    font-weight: 400;
    margin-bottom: 0;
    @include mobile_device {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .popover {
    border: none;
    .popover-arrow {
      display: none;
    }
    .popover-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.popover {
  .popover-body {
    .options__box {
      position: absolute;
      left: 0;
      bottom: calc(100% + 8px);
      z-index: 2;
      width: 220px;
      padding: 32px 16px;
      background: var(--background-color);
      border: 1px solid var(--border-color);
      box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
      border-radius: 16px;
      text-align: center;
      visibility: visible;
      opacity: 1;
      transition: all 0.2s;
      .options__stage {
        margin-bottom: 24px;
        @include fontWeight(font-weight, 500, 700);
      }
      .options__share {
        display: flex;
        justify-content: center;
      }
      .options__direction {
        &:not(:last-child) {
          margin-right: 24px;
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background: var(--border-color);
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            background-color: $themeColor;
            .icon * {
              fill: var(--background-color);
            }
          }
          .icon {
            width: 20px;
            height: 20px;
            transition: fill 0.2s;
            * {
              fill: #000000;
            }
          }
        }
      }
    }
  }
}
[dir="ltr"] {
  [class*="button-next"] {
    right: -60px;
    @media screen and (min-width: 1200px) and (max-width: 1366px) {
      right: -40px;
    }
    @include extra_large {
      right: auto;
      left: calc(50% - 0px);
    }
  }
  [class*="button-prev"] {
    left: -60px;
    @media screen and (min-width: 1200px) and (max-width: 1366px) {
      left: -40px;
    }
    @include extra_large {
      left: calc(50% - 50px);
    }
  }
  .customBTab {
    .tab-pane {
      padding-left: 2px;
    }
  }
  .slick-track {
    margin-left: 0;
  }
  .react-datepicker-popper {
    .react-datepicker {
      font-family: $fontFamilyltr;
    }
  }
  .form-check {
    input {
      left: 0;
    }
    .form-check-label {
      margin-left: 20px;
      &::before {
        left: -40px;
      }
      &:after {
        left: -28px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
  body {
    [class^="btn"] {
      &.p-end {
        padding-right: 40px;
        svg {
          right: 20px;
        }
      }
    }
    .p-start {
      svg {
        margin-right: 10px;
      }
    }
  }
}
.hero {
  font-size: 96px;
  line-height: 1;
  letter-spacing: -0.02em;
}

.text-green {
  color: $successColor !important;
}
.text-red {
  color: $errorColor !important;
}
img {
  object-fit: cover;
}
.bg_gray {
  background: var(--light-bg);
}
.logo_style {
  font-size: 30px;
  @include fontWeight(font-weight, 500, 700);
  color: var(--desc-font);
  display: inline-block;
  &:hover {
    color: var(--desc-font) !important;
  }
}
.valmsg {
  font-size: 13px;
  margin-top: 5px;
  color: $errorColor;
  display: block;
  line-height: 18px;
  word-break: break-word;
}
.disableDiv,
.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}
[class^="section"] {
  padding: 64px 0;
  @include large_device {
    padding-left: 3px;
    padding-right: 3px;
    &.hot_collection,
    &.mobileSpaceDiv {
      padding-bottom: 100px;
    }
    &.mobileSpaceDiv {
      padding-left: 0;
      padding-right: 0;
      .container {
        padding: 0;
      }
    }
  }
  @include medium_device {
    padding: 35px 3px;
  }
}
.section_pt80 {
  padding-top: 64px;
  @include medium_device {
    padding-top: 35px;
  }
}

[class^="status"] {
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--background-color);
}

// Table
.table-responsive {
  &::-webkit-scrollbar {
    display: none;
  }
}
.customTable {
  th {
    @include fontWeight(font-weight, 500, 700);
    font-size: 16px;
    color: var(--variantColor);
    @include large_device {
      font-size: 14px;
    }
  }
  tbody {
    border-top: 1px solid var(--border-color) !important;

    tr {
      &:hover {
        background-color: var(--light-bg);
      }
    }
    td {
      vertical-align: middle;
      font-size: 16px;
      @include fontWeight(font-weight, 500, 700);
      color: var(--variantColor);
      border-bottom: 1px solid var(--border-color);
      @include large_device {
        font-size: 14px;
      }
    }
  }
  textarea {
    &::placeholder {
      font-size: 14px;
      color: $customFont;
      font-weight: 400;
    }
  }
}
.mainSubtitle {
  margin-bottom: 20px;
  line-height: 64px;
  letter-spacing: -0.02em;
  color: var(--desc-font);
  @include fontWeight(font-weight, bold, 700);
  @include large_device {
    font-size: 48px;
    line-height: 50px;
  }
}

// Breadcrumb
.breadcrumb {
  margin: 0;
  .breadcrumb-item {
    font-size: 16px;
    line-height: 24px;
    @include mobile_device {
      font-size: 14px;
    }
    & + .breadcrumb-item {
      padding: 0;
      &::before {
        content: "";
        background: url("../public/img/leftArrow.svg") center;
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0 15px;
        @include mobile_device {
          margin: 0 8px;
          width: 8px;
        }
      }
      &.active {
        color: $themeColor;
      }
    }
  }
  li {
    &.active {
      color: $themeColor !important;
    }
    a {
      &:hover {
        color: var(--variantColor);
      }
    }
  }
}

.navbar-collapse {
  .nav-item {
    &.dropdown {
      @include large_device {
        .dropdown-menu {
          .dropdown-item {
            display: flex;
            align-items: center;
            span {
              margin-right: 6px !important;
              width: 16px !important;
              height: 16px !important;
            }
          }
        }
        &.show {
          background: var(--light-bg);
          border-radius: 8px;
          padding: 5px 15px;
          .dropdown-menu {
            display: block !important;
            position: relative;
            padding: 0;
            border: none;
            box-shadow: none;
            min-width: 100%;
            max-width: 100%;
            background: var(--light-bg);
            margin-top: 10px;
            .dropdown-item {
              border-radius: 8px;
              &.active {
                background: #e6e8ec;
                color: var(--variantColor);
                font-weight: 700;
              }
            }
            &:empty{
              display: none !important;
            }
          }
        }
      }
    }
  }
}
.navbar {
  .navbar-toggler {
    -webkit-box-shadow: 0 0 0 2px var(--border-color) inset;
    box-shadow: 0 0 0 2px var(--border-color) inset;
    background-color: transparent;
    svg * {
      fill: $customFont !important;
    }
    .collapsedToggle {
      display: inline-flex;
    }
    .navbar_expand,
    & ~ .navbar-collapse {
      display: none;
    }
    .collapsedToggle {
      display: none;
    }
  }
}
.dropdown {
  .dropdown-menu {
    background-color: var(--background-color);
    border-radius: 5px;
    border: none;
    -webkit-box-shadow: 0px 20px 64px 0px rgb(31 47 70 / 25%);
    box-shadow: 0px 20px 64px 0px rgb(31 47 70 / 25%);
    box-sizing: border-box;
    padding: 0;
    top: 100%;
    left: 0;
    min-width: 200px;
    max-width: 200px;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    z-index: 9;
    @include large_device {
      -webkit-transform: translate(0);
      transform: translate(0);
    }
    .dropdown-item {
      padding: 10px 8px;
      color: $customFont;
      display: block;
      font-size: 14px;
      border-radius: 4px;
      &.active,
      &:hover,
      &:focus {
        background: var(--light-bg);
        color: var(--variantColor);
        font-weight: 700;
      }
    }
  }
  &:hover {
    & > button {
      &::after {
        -webkit-transform: rotate(180deg) translateY(50%);
        transform: rotate(180deg) translateY(50%);
        @include large_device {
          -webkit-transform: rotate(0deg) translateY(50%);
          transform: rotate(0deg) translateY(50%);
        }
      }
    }
    .dropdown-menu {
      @include large_device {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
      }
    }
  }
}
.dropdown,
.dropup {
  .dropdown-toggle {
    &::after {
      content: none;
    }
  }
}
.discover_filter_m {
  li {
    .dropdown-menu {
      opacity: 1;
      overflow: visible;
      display: none;
      transform: scale(1) translateY(0);
      left: 0 !important;
      top: 0 !important;
      transform-origin: 0 0;
      transition: all 0.2s ease-in-out;
      &.show {
        display: block;
      }
    }
  }
}

// react select
.selectMenu {
  &.menuWidth {
    .select__Inner__control {
      min-width: 190px;
    }
  }
  .select__Inner__single-value,
  .select__Inner__option {
    display: flex;
    align-items: center;
    span {
      & + span {
        margin-left: 8px;
      }
    }
  }
  .select__Inner__control {
    background: var(--background-color);
    border: none;
    box-shadow: inset 0 0 0 2px var(--border-color);
    border-radius: 12px;
    height: 48px;
    &.select__Inner__control--menu-is-open {
      -webkit-box-shadow: inset 0 0 0 2px $customFont;
      box-shadow: inset 0 0 0 2px $customFont;
      .select__Inner__indicator {
        -webkit-box-shadow: inset 0 0 0 2px $customFont;
        box-shadow: inset 0 0 0 2px $customFont;
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .select__Inner__value-container {
      padding: 2px 7px;
      position: relative;
      .select__Inner__placeholder,
      .select__Inner__single-value {
        @include fontWeight(font-weight, 500, 700);
        font-size: 14px;
        line-height: 24px;
        color: var(--desc-font);
      }
      .select__Inner__placeholder {
        & + input {
          left: 0;
        }
      }
      input{
        position: absolute;
        right: 0;
      }
    }
    .select__Inner__indicator-separator {
      display: none;
    }
    .select__Inner__indicator {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px var(--border-color);
      margin-right: 8px;
      padding: 0;
      align-items: center;
      justify-content: center;
      svg {
        -webkit-transition: transform 0.2s, -webkit-transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
      }
    }
    & + .select__Inner__menu {
      -webkit-box-shadow: 0 4px 12px rgba(35, 38, 47, 0.1);
      box-shadow: 0 4px 12px rgba(35, 38, 47, 0.1);
      border: 2px solid var(--border-color);
      background: var(--background-color);
      border-radius: 12px;
      overflow: hidden;
      z-index: 9;
      .select__Inner__menu-list {
        // background: var(--background-color);
        padding: 10px;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(--border-color);
        }
        .select__Inner__option {
          padding: 10px 14px;
          @include fontWeight(font-weight, 500, 700);
          line-height: 20px;
          color: var(--desc-font);
          cursor: pointer;
          border-radius: 6px;
          line-height: normal;
          &.select__Inner__option--is-selected {
            @include fontWeight(font-weight, 500, 700);
            color: $themeColor !important;
            background: var(--light-bg);
            &.select__Inner__option--is-focused {
              @include fontWeight(font-weight, 500, 700);
              color: $themeColor !important;
              background: var(--light-bg);
            }
          }
          &.select__Inner__option--is-focused {
            background-color: transparent;
          }
        }
      }
    }
  }
}

// Modal Place a bid

// common Modal css
.modal {
  z-index: 9999999999 !important;
  .modal-content {
    position: relative;
    padding: 32px;
    background: var(--background-color);
    border-radius: 16px !important;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition: all 0.2s ease-in-out;
    @include medium_device {
      padding: 20px;
    }
  }
  &.show {
    .modal-content {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
.modal-backdrop {
  z-index: 99999 !important;
  &.show {
    opacity: 0.9 !important;
  }
}

body .popup__title {
  margin-bottom: 32px;
  &.h4 {
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: -0.01em;
    margin-bottom: 32px;
    @include medium_device {
      font-size: 25px !important;
    }
  }
  .btnClose {
    position: absolute !important;
    top: 32px;
    right: 32px;
    width: 40px !important;
    height: 40px !important;
    border: 2px solid var(--border-color) !important;
    border-radius: 50% !important;
    line-height: normal;
    opacity: 1;
    background: var(--background-color) url("../public/img/closePopup.svg")
      no-repeat 50% 48%/14px auto !important;
    font-size: 0 !important;
    transition: all 0.2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 0 !important;
    cursor: pointer !important;
    z-index: 1;
    @include medium_device {
      top: 20px;
      right: 20px;
    }
    svg * {
      fill: var(--variantColor) !important;
    }
    &:hover,
    &:focus {
      border-color: var(--border-color) !important;
      background: var(--background-color) url("../public/img/closePopup.svg")
        no-repeat 50% 48%/14px auto !important;
    }
  }
  &.itemDetailImg {
    button {
      top: -20px;
      right: -20px;
    }
  }
}
.popup__body {
  img {
    border-radius: 8px;
  }
}
.popup__info {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--desc-font);
  font-weight: 400;
}
.popup__subtitle {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 1.33333;
  @include fontWeight(font-weight, 600, 700);
  letter-spacing: -0.01em;
}
.popup__row {
  display: flex;
  padding-bottom: 12px;
  justify-content: space-between;
  &.total_amt {
    border-top: 1px solid var(--border-color);
    padding-top: 12px;
    padding-bottom: 0;
    & > * {
      color: var(--variantColor);
      @include fontWeight(font-weight, 500, 700);
    }
  }
  .popup__col {
    font-size: 16px;
    line-height: 1.5;
    &:first-child {
      color: $customFont;
    }
  }
  .popup__bid {
    display: flex;
    align-items: center;
    .popup__currency {
      margin-left: 4px;
    }
    .popup__rate {
      min-width: 100px;
      height: 24px;
      background: none;
      border: none;
      text-align: right;
      @include fontWeight(font-weight, 500, 700);
      font-size: 16px;
    }
  }
}
.popup__btns {
  margin-top: 32px;
  .btn {
    width: 100%;
    & + .btn {
      margin-left: 10px;
      @include medium_device {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}
.popup__note {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: $customFont;
}
.popup__attention {
  display: flex;
  align-items: center;
  margin-top: 32px;
  padding: 24px;
  border-radius: 8px;
  background: rgba(239, 70, 111, 0.08);
  color: $errorColor;
  @include medium_device {
    align-items: stretch;
  }
  svg {
    width: 32px;
    height: 32px;
    * {
      fill: $errorColor;
    }
  }
  .popup__avatar {
    flex-shrink: 0;
    margin-left: 16px;
  }
}
.popup__avatar {
  width: 48px;
  height: 48px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.popup__preview {
  flex-shrink: 0;
  width: 32px;
  margin-right: 16px;
}
.popup__details {
  flex-grow: 1;
  .popup__category {
    font-size: 16px;
    line-height: 1.5;
    @include fontWeight(font-weight, 500, 700);
  }
  .popup__text {
    font-size: 12px;
    line-height: 1.66667;
  }
}
.popup__subtitle {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 1.33333;
  @include fontWeight(font-weight, 600, 700);
  letter-spacing: -0.01em;
}
.discModals{
  .modal-dialog{
    max-width: 536px;
  }
  .discModal{
    padding-top: 40px;
    .popup__title{
      margin-bottom: 8px;
    }
    .disc{
      margin-bottom: 24px;
      text-align: center;
    }
    .d_label{
      padding-bottom: 20px;
      p{
        color: #B1B5C4;
        @include fontWeight(font-weight, 600, 700);
        text-transform: uppercase;
      }
      .blueColr{
        color: $themeColor;
      }
      .dolorPrice{
        margin-left: 6px;
        @include fontWeight(font-weight, 400, 500);
        font-size: 15px;
      }
    }
    .popup__note{
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
    }
    .D_rangeSlide{
      .rangeslider-horizontal{
        height: 8px;
        box-shadow: none;
        background: var(--border-color);
        .rangeslider__fill{
          box-shadow: none;
          background-color: $themeColor;
        }
        .rangeslider__handle{
          height: 20px;
          width: 20px;
          box-shadow: none;
          &::after{
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            height: 10px;
            width: 10px;
            box-shadow: none;
            background-color: $themeColor;
          }
          .rangeslider__handle-label{
            display: none;
          }
        }
        .rangeslider__label-item{
          color: var(--desc-font);
          font-weight: 500;
        }
        .rangeslider__tooltip__label{
          background-color: var(--variantColor);
        }
        .rangeslider__tooltip__caret{
          &::before{
            border-top-color:var(--variantColor);
          }
        }
      }
    }
    .disMinfo{
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin-top: 12px;
    }
    .valmsg{
      text-align: center;
      margin-top: 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $errorColor;
    }
  }
}

.field__input {
  height: 48px;
  padding: 0 14px;
}
.field__textarea {
  height: 96px;
  padding: 10px 14px;
  resize: none;
}

.field__input,
.field__textarea {
  width: 100%;
  border-radius: 12px;
  background: none;
  font-size: 14px;
  line-height: 1.71429;
  @include fontWeight(font-weight, 500, 700);
  color: var(--desc-font);
  transition: border-color 0.2s;
}

.field__label {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--border-color);
}
.field__wrap {
  position: relative;
}

.field__input,
.field__currency {
  font-size: 24px;
  @include fontWeight(font-weight, 600, 700);
  text-transform: uppercase;
}
.field__currency {
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

// success popup
.success {
  padding-top: 40px;
  .success__title {
    margin-bottom: 32px;
    text-align: center;
  }
  .success__info {
    max-width: 240px;
    margin: 0 auto 32px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    @include fontWeight(font-weight, 500, 700);
  }
  .success__table {
    padding: 24px;
    border-radius: 12px;
    border: 1px solid var(--border-color);
  }
  .success__row {
    display: flex;
    justify-content: space-between;
    &:first-child {
      color: $customFont;
      @include fontWeight(font-weight, 500, 700);
    }
    &:nth-child(2) {
      @include fontWeight(font-weight, 500, 700);
      .success__col:first-child {
        color: #9757d7;
      }
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .success__col {
    &:nth-child(2) {
      flex-shrink: 0;
      width: 152px;
    }
  }
  .success__stage {
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    @include fontWeight(font-weight, 500, 700);
  }
  .success__socials {
    display: flex;
    justify-content: center;
  }
  .success__social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border: 2px solid var(--border-color);
    border-radius: 50%;
    transition: border-color 0.2s;
    &:not(:last-child) {
      margin-right: 16px;
    }
    &:hover {
      border-color: $themeColor;
      svg * {
        fill: $themeColor;
      }
    }
    svg {
      width: 24px;
      height: 24px;
      transition: fill 0.2s;
      * {
        fill: $customFont;
      }
    }
  }
}

.placeBid {
  .steps {
    .error {
      .steps__icon {
        background: transparent;
      }
      .btn {
        box-shadow: 0 0 0 2px $errorColor inset;
        color: $errorColor;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .steps__note {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 20px;
    color: #777e91;
  }
}
.pagesLoader {
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .text {
    color: var(--desc-font);
    margin-top: 10px;
  }
}
.steps__icon {
  width: 48px;
  height: 48px;
  position: relative;
  .loader-circle {
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: 2;
    background: var(--background-color);
    font-size: 10px;
    text-indent: -9999em;
    border-radius: 50%;
    border-top: 5px solid var(--border-color);
    border-right: 5px solid var(--border-color);
    border-bottom: 5px solid var(--border-color);
    border-left: 5px solid $themeColor;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loader-circle 1.1s infinite linear;
    animation: loader-circle 1.1s infinite linear;
    @-webkit-keyframes loader-circle {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes loader-circle {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
.profileReport {
  .field__textarea {
    height: 96px;
    padding: 10px 14px;
    resize: none;
    border-radius: 12px;
  }
}

.steps {
  .steps__item {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    &.done {
      .steps__icon:before {
        opacity: 1;
      }
    }
  }
  .steps__head {
    display: flex;
  }
  .steps__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 20px;
    border: 2px solid var(--border-color);
    border-radius: 50%;
    transition: all 0.2s;
    &:before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: 50%;
      background: #45b26b url("../public/img/tickMark.svg") no-repeat 50% 50%/14px
        auto;
      opacity: 0;
      transition: opacity 0.2s;
    }
    .icon {
      width: 24px;
      height: 24px;
      fill: $customFont;
      transition: fill 0.2s;
    }
  }
  .steps__info {
    font-size: 16px;
    line-height: 1.5;
    @include fontWeight(font-weight, 500, 700);
  }
  .steps__text {
    font-size: 12px;
    line-height: 1.66667;
    color: $customFont;
  }
}

// Upload detail
.preview__clear {
  display: inline-flex;
  align-items: center;
  margin-top: 24px;
  font-family: $secondaryFont;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: $customFont;
  transition: color 0.2s;
  cursor: pointer;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: $customFont;
    transition: fill 0.2s;
  }
  &:hover {
    color: $themeHoverColor;
    .icon * {
      fill: $themeHoverColor;
    }
  }
}
.uploadD {
  @include large_device {
    .previews {
      width: 100%;
      display: block !important;
    }
  }
  .preview__clear {
    display: inline-flex;
    align-items: center;
    margin-top: 24px;
    font-family: $secondaryFont;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: $customFont;
    transition: color 0.2s;
    cursor: pointer;
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: $customFont;
      transition: fill 0.2s;
    }
    &:hover {
      color: $themeHoverColor;
      .icon * {
        fill: $themeHoverColor;
      }
    }
  }
  .selectMenu {
    .select__Inner__control {
      border-radius: 16px;
      height: 50px;
    }
    .select__Inner__value-container {
      height: 100%;
    }
    .select__Inner__input-container {
      padding: 0;
      margin: 0;
    }
  }
}

.lbl {
  color: #b1b5c4;
  @include fontWeight(font-weight, bold, 700);
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 12px;
  text-transform: uppercase;
  display: inline-block;
}

// Range slider
.rangeSlider {
  .rangeslider__handle-tooltip {
    top: -50px !important;
    color: var(--variantColor) !important;
    background-color: var(--border-color) !important;
    line-height: 40px;
    padding: 0 15px;
    width: auto !important;
    &::after {
      border-top-color: var(--border-color) !important;
    }
  }
  .range_indicators {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    .range_text {
      @include fontWeight(font-weight, 500, 700);
    }
  }
  .rangeslider-horizontal {
    height: 8px;
    border-radius: 4px;
    border: none;
    background: var(--border-color);
    box-shadow: none;
    margin: 5px 0 10px;
    .rangeslider__fill {
      background-color: $themeColor;
      box-shadow: none;
    }
    .rangeslider__handle {
      width: 24px;
      height: 24px;
      box-shadow: none;
      border: none;
      &:after {
        width: 14px;
        height: 14px;
        top: 5px;
        left: 5px;
        background-color: $themeColor;
        box-shadow: none;
        border: none;
      }
    }
  }
}

// Black Tabs

.list {
  .selectMenu {
    .select__Inner__control {
      border-radius: 12px;
      svg {
        height: 100%;
        fill: #777e91;
      }
    }
    .select__Inner__value-container {
      height: 100%;
    }
    .select__Inner__input-container {
      padding: 0;
      margin: 0;
    }
  }
}

// popularSeller
.popularSeller {
  .slick-slide {
    height: auto;
    padding: 0 16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;
    &.slick-active {
      opacity: 1;
      visibility: visible;
    }
  }
  .popular__box {
    .selectMenu {
      & > div {
        .select__Inner__control {
          &.select__Inner__control--menu-is-open {
            background-color: var(--background-color) !important;
          }
        }
      }
    }
  }
}
// slider arrow
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--background-color);
  border: 2px solid var(--border-color);
  font-size: 0;
  transition: all 0.2s;
}

// slick slider
.slick-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.slick-slider {
  @media screen and (min-width: 992px) {
    margin: 0 -12px;
  }
  & > div {
    // &[class$="list"] {
    //   direction: ltr;
    // }
    &[class*="prev"],
    &[class*="next"] {
      top: -108px;
      position: absolute;
      z-index: 2;
      width: 40px;
      height: 40px;
      border: 2px solid transparent;
      border-radius: 50%;
      font-size: 0;
      transition: all 0.2s;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
      @include medium_device {
        top: -93px;
      }
      &:hover {
        border-color: var(--border-color);
      }
    }
    &[class*="prev"] {
      right: 50px;
      left: auto;
    }
    &[class*="next"] {
      right: 0;
      left: auto;
    }
  }
  .slick-slide {
    padding: 0 15px;
    @include mobile_device {
      padding: 0 5px;
    }
  }
  .slick-list {
    direction: ltr;
    .slick-track {
      @include mobile_device {
        display: flex;
      }
    }
  }
}
.accordion {
  .card-header {
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);
  }
}
// modal css
@include medium_device {
  [dir="rtl"],
  [dir="ltr"] {
    .modal-dialog {
      padding: 15px;
      margin: auto;
      min-height: 100%;
    }
  }
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
  & > div {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    background: white;
  }
  header {
    @include fontWeight(font-weight, bold, 700);
  }
  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
  &:hover {
    color: black;
  }
}

// Detail page
.transferToken {
  .popup__input {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid var(--border-color);
    background: none;
    border-radius: 0;
    font-size: 16px;
    line-height: 1.5;
    @include fontWeight(font-weight, 500, 700);
    color: var(--desc-font);
    -webkit-transform: border-color 0.2s;
    transform: border-color 0.2s;
  }
}

.nav-pills {
  .nav-item {
    .nav-link {
      cursor: pointer;
      &.active {
        color: var(--desc-font);
        background-color: transparent;
        svg {
          path {
            fill: var(--desc-font);
          }
        }
      }
    }
  }
}

// Faq section
.faq {
  .nav-pills {
    .nav-item .nav-link {
      padding: 10px 14px;
      &.active {
        background-color: transparent;
        color: $darkbgColor;
      }
    }
  }
}

// Show more text
.showMoreSec {
  .showBtnDiv {
    background-color: var(--background-color);
    width: 100%;
    padding: 0 !important;
    margin-top: 5px;
    &:before {
      content: "";
      mask: linear-gradient(var(--background-color), transparent);
      -webkit-mask: linear-gradient(var(--background-color), transparent);
      left: 0;
      top: -7px;
      background-color: var(--background-color);
      width: 100%;
      height: 100%;
      filter: blur(3px);
      position: absolute;
      z-index: 1;
    }
    .showMoreBtn {
      background-color: transparent !important;
      padding: 3px !important;
      color: $customFont !important;
      z-index: 2;
      font-size: 14px;
      font-weight: 700;
      font-family: $secondaryFont;
    }
  }
}
.loadmoreBtn {
  span {
    width: 3px;
    height: 3px;
    border-radius: 100%;
    margin: 2px;
    background-color: $darkbgColor;
    position: relative;
    bottom: -3px;
    display: inline-block;
    -webkit-animation: loadingAnim 1.5s 0.5s linear infinite;
    animation: loadingAnim 1.5s 0.5s linear infinite;
    &.first {
      animation-delay: 0.1s;
    }
    &.second {
      animation-delay: 0.2s;
    }
    &.third {
      animation-delay: 0.3s;
    }
    @-webkit-keyframes loadingAnim {
      0%,
      50%,
      100% {
        transform: scale(1);
      }
      25% {
        transform: scale(0.6);
      }
      75% {
        transform: scale(1.1);
      }
    }
    @keyframes loadingAnim {
      0%,
      50%,
      100% {
        transform: scale(1);
      }
      25% {
        transform: scale(0.6);
      }
      75% {
        transform: scale(1.1);
      }
    }
  }
}
[class$="MuiDialog-root"] {
  z-index: 9999999999;
  [class^="MuiBackdrop-root"] {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
[class$="newsBanner"] {
  @media only screen and (max-width: 375px) {
    > div {
      -webkit-grid-template-columns: 16px 96px auto 80px;
      grid-template-columns: 16px 96px auto 80px;
      > div:last-child {
        margin-right: 15px;
        a {
          font-size: 17px;
        }
      }
    }
  }
}

.pagination{
  margin: 0;
  padding: 18px 0;
  flex-wrap: wrap;
  justify-content: center;
  .page-item{
    margin: 0 5px;
      &:last-child, &:first-child{
        &.disabled{
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }
    @include medium_device {
      margin: 0 4px;
    }
    &.active, &:hover{
      .page-link{
        color: $lightColor;
        background: $themeColor;
        font-weight: 600;
      }
    }
    .page-link{
      border: none;
      background-color: transparent;
      border-radius: 4px !important;
      font-size: 16px;
      line-height: 50px;
      font-weight: 500;
      min-height: 50px;
      min-width: 50px;
      text-align: center;
      height: 50px;
      padding: 0;
      position: relative;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      color: inherit;
      @include medium_device {
        font-size: 15px;
        line-height: 36px;
        height: 36px;
        min-height: 36px;
        min-width: 36px;
      }
      &:focus{
        box-shadow: none;
      }
    }
    &:first-child{
      .page-link{
        font-size: 0;
        &::before{
          content: "<";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          font-size: 20px;
        }
        &:not(:hover){
          &::before{
            color: #777e90;
          }
        }
      }
    }
    &:last-child{
      .page-link{
        font-size: 0;
        &::before{
          content: ">";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          font-size: 20px;
        }
        &:not(:hover){
          &::before{
            color: #777e90;
          }
        }
      }
    }
    &.disabled{
      .page-link{
        background: transparent;
        color: inherit;
        font-weight: 400;
      }
    }
  }
}

[class*="heights"] ~ main [class*="article_detials"] [class*="stickyWrap"]{
  top: 165px;
}

[data-theme="dark"] {
  .shimmer {
    background: -webkit-linear-gradient(
      to right,
      $darkSecColor 8%,
      $secondaryBg 18%,
      $darkSecColor 33%
    );
    background: linear-gradient(
      to right,
      $darkSecColor 8%,
      $secondaryBg 18%,
      $darkSecColor 33%
    );
  }
  .modal-backdrop {
    background-color: $secondaryBg;
  }
  .dropdown {
    .dropdown-menu {
      .dropdown-item {
        &.active {
          background-color: $darkSecColor;
        }
      }
    }
  }
  .light {
    display: none !important;
  }
  .dark {
    display: inline-block !important;
  }
  .faq {
    .nav-pills {
      .nav-item .nav-link {
        &.active {
          color: $lightColor;
        }
      }
    }
  }
  .popover {
    .dropdown_menu {
      .catDetails {
        li {
          &:hover {
            background: $secondaryBg;
          }
        }
      }
    }
  }
  .select__Inner__control {
    .select__Inner__indicator {
      svg * {
        fill: $customFont;
      }
    }
    &.select__Inner__control--is-focused {
      .select__Inner__indicator {
        svg * {
          fill: $customFont;
        }
      }
    }
  }
  .breadcrumb{
    .breadcrumb-item + .breadcrumb-item::before{
      -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(12deg) brightness(108%) contrast(106%);
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(12deg) brightness(108%) contrast(106%);
    }
  }
}
[dir="rtl"] {
  .ql-editor {
    text-align: right;
    &.ql-blank {
      &::before {
        right: 15px;
        left: auto;
      }
    }
  }
  .tabSlider {
    .swiper-button-prev {
      left: -40px;
      right: auto;
      transform: rotate(0deg) translateY(30%);
    }
    .swiper-button-next {
      left: -74px;
      right: auto;
      transform: translateY(30%) rotate(-180deg);
    }
    .swiper-slide {
      & > span {
        margin-left: 8px !important;
        margin-right: 0px !important;
      }
    }
  }
  .walletconnect-modal__header {
    p {
      margin-right: 5px;
      margin-left: 0;
    }
  }
  .walletconnect-modal__mobile__toggle {
    &.right__selected {
      .walletconnect-modal__mobile__toggle_selector {
        transform: translate3d(calc(100% - 353px), 0, 0);
        right: 4px;
      }
    }
  }
  .walletconnect-modal__mobile__toggle_selector {
    right: 8px;
  }
  .walletconnect-modal__close__wrapper {
    left: 0;
    right: auto;
  }
  [class*="button-next"] {
    left: -60px;
    right: auto;
    -webkit-transform: rotate(-180deg) translateY(50%);
    transform: rotate(-180deg) translateY(50%);
    @media screen and (min-width: 1200px) and (max-width: 1366px) {
      left: -40px;
    }
    @include extra_large {
      right: calc(50% + 10px);
      left: auto;
    }
  }
  [class*="button-prev"] {
    right: -60px;
    left: auto;
    -webkit-transform: rotate(180deg) translateY(50%);
    transform: rotate(180deg) translateY(50%);
    @media screen and (min-width: 1200px) and (max-width: 1366px) {
      right: -40px;
    }
    @include extra_large {
      right: calc(50% - 40px);
    }
  }
  .navbar-collapse {
    .nav-item {
      &.dropdown {
        .dropdown-menu {
          .dropdown-item {
            span {
              margin-left: 6px !important;
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }
  .customBTab {
    .tab-pane {
      padding-left: 2px;
    }
    .nav-tabs {
      li {
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-right: 12px;
        }
      }
    }
  }
  .steps {
    .steps__icon {
      margin-right: 0;
      margin-left: 20px;
    }
  }
  .back {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .slick-track {
    margin-right: 0;
  }
  .uploadD {
    .preview__clear {
      .icon {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }
  .react-datepicker-popper {
    .react-datepicker {
      font-family: $fontFamilyrtl;
    }
  }
  .form-switch {
    .form-check-input {
      &:checked {
        background-position: 4px 50%;
      }
    }
  }
  .popup__btns {
    .btn {
      & + .btn {
        margin-right: 10px;
        margin-left: 0;
        @include medium_device {
          margin-right: 0;
        }
      }
    }
  }
  .discModals{
    .rangeslider-horizontal{
      .rangeslider__handle{
        -webkit-transform: translate3d(50%, -50%, 0);
        transform: translate3d(50%, -50%, 0);
      }
      .rangeslider__label-item{
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
      }
    }
    .dolorPrice{
      float: left;
      margin-left: 0 !important;
      margin-right: 6px !important;
    }
  }
  .steps {
    .steps__icon {
      margin-left: 20px;
      margin-right: 0;
    }
  }
  .dropdown {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
  .popup__title {
    .btnClose {
      left: 32px;
      right: auto;
      @include medium_device {
        left: 20px;
      }
    }
    &.itemDetailImg {
      button {
        right: auto;
        left: -20px;
      }
    }
  }
  .selectMenu {
    .select__Inner__control {
      .select__Inner__indicator {
        margin-left: 8px;
        margin-right: 0;
      }
    }
    .select__Inner__single-value,
    .select__Inner__option {
      span {
        & + span {
          margin-right: 8px;
          margin-left: 0;
        }
      }
    }
  }
  .slick-slider {
    & > div {
      &[class*="prev"],
      &[class*="next"] {
        right: auto;
      }
      &[class*="prev"] {
        left: 0;
      }
      &[class*="next"] {
        left: 50px;
      }
    }
  }
  .form-check-label {
    margin-right: 20px;
    margin-left: 0;
    &::before {
      right: -40px;
      left: auto;
    }
    &:after {
      right: -28px;
      -webkit-transform: translate(50%, -50%);
      transform: translate(50%, -50%);
    }
  }
  .form-check {
    input {
      right: 0;
      left: auto;
    }
    .form-check-input {
      margin-left: -1.5em;
      margin-right: 0;
    }
  }
  body {
    [class^="btn"] {
      &.p-end {
        padding-left: 40px;
        svg {
          left: 20px;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      &.p-start {
        svg {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          margin-left: 10px;
        }
      }
    }
  }
  .form-control {
    direction: rtl;
  }
  .breadcrumb {
    .breadcrumb-item {
      & + .breadcrumb-item {
        &::before {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
  [class$="newsBanner"] {
    @media only screen and (max-width: 375px) {
      > div {
        > div:nth-child(2) {
          padding-left: 0;
          padding-right: 10px;
        }
      }
    }
  }
}
span.show-more-less-clickable {
  color: blue;
  cursor:pointer;
  text-decoration:underline;
  color: $themeColor;
}

span.show-more-less-clickable:hover{
  text-decoration:none;
}
.ensBanner{
  > div{
    z-index: 99999;
    position: sticky;
    top: 0px;
    box-shadow: none;
    background-color: rgb(244, 244, 244);
    color: rgb(20, 20, 20);
    white-space: nowrap;
  }
}
.direCtions{
  direction: ltr;
}